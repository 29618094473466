import { type ReactNode, useCallback, useEffect, useState } from "react";
import type { EmblaCarouselType } from "embla-carousel";
import useEmblaCarousel, {
    type UseEmblaCarouselType,
} from "embla-carousel-react";
import * as Dialog from "@radix-ui/react-dialog";
import { cx } from "class-variance-authority";
import { Image, imgproxyLoader } from "@/lib/imgproxy";
import { ArrowExpandFilled } from "./Icons";
import { ChevronButton } from "./Button/ChevronButton";
import { useTranslation } from "react-i18next";

const getSize = (length: number, sequence: number): string => {
    let sizes = "550px";
    if (length === 1)
        sizes = "1100px"; // 1 image
    else if (length > 1 || sequence === 0)
        sizes = "550px"; // 2 - 3 images || 1st image
    else if (length > 3) sizes = "300px"; // 4 - 5 images
    return sizes;
};

const defaultImg = "/images/default_centre.jpg";
const Link = ({
    src,
    className,
    children,
}: {
    src: string;
    className?: string;
    children: ReactNode;
}): JSX.Element => {
    if (src === defaultImg) return <div>{children}</div>;
    let s = src;
    if (src.startsWith("/"))
        s = new URL(src, window.location.origin).toString();
    return (
        <a
            className={className}
            href={imgproxyLoader({ src: s, width: "750" })}
            target="_blank"
        >
            {children}
        </a>
    );
};
export const DialogGallery = ({ srcs }: { srcs: string[] }): JSX.Element => {
    const { t } = useTranslation("components/CentreDetailsGallery");
    const [idx, setIdx] = useState(0);
    const [emblaRef, emblaApi] = useEmblaCarousel();
    useEffect(() => {
        const setFn = (a: EmblaCarouselType): void => {
            setIdx(a.selectedScrollSnap());
        };
        emblaApi?.on("select", setFn);
        return () => {
            emblaApi?.off("select", setFn);
        };
    }, [emblaApi]);
    const scrollTo = useCallback(
        (i: number) => {
            emblaApi?.scrollTo(i);
        },
        [emblaApi],
    );
    const scrollPrev = useCallback(() => {
        emblaApi?.scrollPrev();
    }, [emblaApi]);
    const scrollNext = useCallback(() => {
        emblaApi?.scrollNext();
    }, [emblaApi]);
    srcs = srcs.length ? srcs : [defaultImg];
    return (
        <>
            <div className="group relative md:hidden" data-length={srcs.length}>
                <CarouselImage
                    emblaRef={emblaRef}
                    emblaApi={emblaApi}
                    srcs={srcs}
                    scrollPrev={scrollPrev}
                    scrollNext={scrollNext}
                    scrollTo={scrollTo}
                    idx={idx}
                />
            </div>
            <div className="relative hidden md:flex">
                <div
                    className="group grid w-full grid-cols-4 grid-rows-[repeat(2,151px)] items-center gap-4 overflow-hidden"
                    data-length={srcs.length}
                >
                    {srcs.slice(0, 5).map((s, i) => (
                        <Link
                            key={s}
                            className={cx(
                                "remove-styles-a relative aspect-video size-full cursor-pointer rounded-lg first:col-span-2 first:row-span-2",
                                "only:col-span-4", // 1 image
                                "group-data-[length='2']:col-span-2 group-data-[length='2']:row-span-2", // 2 image
                                "group-data-[length='3']:col-span-2", // 3 images
                                "group-data-[length='4']:[&:nth-child(2)]:col-span-2", // 4 images
                            )}
                            src={s}
                        >
                            <Image
                                alt={`Venue image ${i}`}
                                src={s}
                                unoptimized={s.startsWith("/")}
                                className="rounded-lg"
                                layout="fill"
                                objectFit="cover"
                                priority
                                sizes={getSize(srcs.length, i)}
                            />
                        </Link>
                    ))}
                </div>
                <Dialog.Root>
                    <Dialog.Trigger asChild>
                        <div
                            data-show={
                                srcs[0] === defaultImg ? "false" : "true"
                            }
                            className="absolute bottom-4 right-4 flex cursor-pointer items-center gap-2 rounded-[20px] bg-white px-3 py-1.5 data-[show=false]:hidden"
                        >
                            <ArrowExpandFilled className="size-4 text-blue-grey-400" />
                            {t("allPhotos", "All photos")}
                        </div>
                    </Dialog.Trigger>
                    <Dialog.Portal>
                        <Dialog.Overlay className="fixed inset-0 z-20 bg-black/50" />
                        <Dialog.Content className="fixed inset-1/2 z-20 h-max max-h-[400px] w-dvw max-w-[600px] -translate-x-1/2 -translate-y-1/2 overflow-y-auto bg-white transition transition-none ease-in-out data-[state=closed]:duration-400 data-[state=open]:duration-300 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]">
                            <CarouselImage
                                emblaRef={emblaRef}
                                emblaApi={emblaApi}
                                srcs={srcs}
                                scrollPrev={scrollPrev}
                                scrollNext={scrollNext}
                                scrollTo={scrollTo}
                                idx={idx}
                            />
                        </Dialog.Content>
                    </Dialog.Portal>
                </Dialog.Root>
            </div>
        </>
    );
};

const CarouselImage = ({
    emblaRef,
    emblaApi,
    srcs,
    scrollPrev,
    scrollNext,
    scrollTo,
    idx,
}: {
    emblaRef: UseEmblaCarouselType["0"];
    emblaApi?: UseEmblaCarouselType["1"];
    srcs: string[];
    scrollPrev: () => void;
    scrollNext: () => void;
    scrollTo: (i: number) => void;
    idx: number;
}): JSX.Element => (
    <>
        <div ref={emblaRef} className="overflow-hidden">
            <div className="flex">
                {srcs.map((s, i) => (
                    <Link
                        key={s}
                        className="remove-styles-a relative aspect-video min-w-0 shrink-0 grow-0 basis-full cursor-pointer"
                        src={s}
                    >
                        <Image
                            alt={`Venue image ${i}`}
                            src={s}
                            unoptimized={s.startsWith("/")}
                            layout="fill"
                            objectFit="cover"
                            priority={i === 0}
                            sizes="(max-width: 576px) 450px,(max-width: 768px) 750px, 700px"
                        />
                    </Link>
                ))}
            </div>
        </div>
        <ChevronButton
            onClick={scrollPrev}
            variant="circled70"
            direction="left"
            size="sm"
            role="navigation"
            className="absolute left-4 top-1/2 -translate-y-1/2 cursor-pointer group-data-[length='1']:hidden"
        />
        <ChevronButton
            onClick={scrollNext}
            variant="circled70"
            direction="right"
            size="sm"
            role="navigation"
            className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer group-data-[length='1']:hidden"
        />
        <nav className="absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center gap-1.5 group-data-[length='1']:hidden">
            {emblaApi?.slideNodes().map((_, i) => (
                <button
                    // using index for key here should be stable since the index is the thing we navigate to
                    key={i}
                    role="navigation"
                    className={cx(
                        "h-[3px] w-6 cursor-pointer rounded-lg border-none outline-none",
                        i === idx ? "bg-white" : "bg-blue-grey-100",
                    )}
                    onClick={() => scrollTo(i)}
                />
            ))}
        </nav>
    </>
);

export const ImagesGallery = ({
    type,
    srcs,
}: {
    type: "layouts" | "directions";
    srcs: string[];
}): JSX.Element => {
    const { t } = useTranslation("components/ImagesGallery");
    const [idx, setIdx] = useState(0);
    const [emblaRef, emblaApi] = useEmblaCarousel();
    useEffect(() => {
        const setFn = (a: EmblaCarouselType): void => {
            setIdx(a.selectedScrollSnap());
        };
        emblaApi?.on("select", setFn);
        return () => {
            emblaApi?.off("select", setFn);
        };
    }, [emblaApi]);
    const scrollTo = useCallback(
        (i: number) => {
            emblaApi?.scrollTo(i);
        },
        [emblaApi],
    );
    const scrollPrev = useCallback(() => {
        emblaApi?.scrollPrev();
    }, [emblaApi]);
    const scrollNext = useCallback(() => {
        emblaApi?.scrollNext();
    }, [emblaApi]);
    if (srcs.length < 1)
        return (
            <div>
                {t(
                    "imageUnavailable",
                    "Venue has not uploaded any images yet.",
                )}
            </div>
        );
    return (
        <div className="group relative h-fit w-full" data-length={srcs.length}>
            <div ref={emblaRef} className="overflow-hidden">
                <div className="flex">
                    {srcs.map((s, i) => (
                        <Link
                            key={s}
                            className="remove-styles-a relative aspect-[3/2] min-w-0 shrink-0 grow-0 basis-full cursor-pointer"
                            src={s}
                        >
                            <Image
                                alt={`Venue ${type} image ${i}`}
                                src={s}
                                unoptimized={s.startsWith("/")}
                                layout="fill"
                                objectFit="contain"
                                sizes="(max-width: 576px) 550px,(max-width: 768px) 750px, 650px"
                            />
                        </Link>
                    ))}
                </div>
            </div>
            <ChevronButton
                onClick={scrollPrev}
                variant="circled70"
                direction="left"
                size="sm"
                role="navigation"
                className="absolute left-4 top-1/2 -translate-y-1/2 cursor-pointer group-data-[length='1']:hidden"
            />
            <ChevronButton
                onClick={scrollNext}
                variant="circled70"
                direction="right"
                size="sm"
                role="navigation"
                className="absolute right-4 top-1/2 -translate-y-1/2 cursor-pointer group-data-[length='1']:hidden"
            />
            <nav className="absolute bottom-4 left-1/2 flex -translate-x-1/2 items-center gap-1.5 group-data-[length='1']:hidden">
                {emblaApi?.slideNodes().map((_, i) => (
                    <button
                        // using index for key here should be stable since the index is the thing we navigate to
                        key={i}
                        role="navigation"
                        className={cx(
                            "h-[3px] w-6 cursor-pointer rounded-lg border-none outline-none",
                            i === idx ? "bg-white" : "bg-blue-grey-100",
                        )}
                        onClick={() => scrollTo(i)}
                    />
                ))}
            </nav>
        </div>
    );
};
