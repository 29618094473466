import { Fragment, memo } from "react";
import { css } from "@emotion/react";
import dayjs from "@/lib/dayjs";
import { Cell } from "./Cell";
import { ResourceHeader } from "./ResourceHeader";
import { styles, topLeftBlankCss } from "./styles";

type TimeHeaderProps = { time: number };
type TimeTableProps = {
    date: dayjs.Dayjs;
    schedule: {
        resource: { uid: string; name: string };
        times: { start: dayjs.Dayjs; end: dayjs.Dayjs }[];
    }[];
    scheduleRanges?: { start: number; end: number }[];
    resourceScheduleRanges?: (
        resourceId: string,
    ) => { start: number; end: number }[];
};
const TimeTable = ({
    date,
    schedule,
    scheduleRanges,
    ...props
}: TimeTableProps): JSX.Element => {
    const startOfDay = date.tz().startOf("d").unix();
    const currentTime = dayjs().tz();
    const currentStartOfDay = currentTime.startOf("d").unix();
    const isDateToday = startOfDay === currentStartOfDay;
    // rounded current time to float value to compare with each row
    const roundedCurrentTime =
        currentTime.hour() + (currentTime.minute() >= 30 ? 0.5 : 0);
    const isBetween = (
        t: number,
        r: { start: dayjs.Dayjs; end: dayjs.Dayjs },
    ): boolean => {
        t = startOfDay + t * 3600;
        const s = r.start.unix();
        const e = r.end.unix();
        return s <= t && t < e;
    };

    let times = Array.from(new Array(48), (_, idx) => idx / 2);
    if (scheduleRanges?.length) {
        times = times.filter((t) =>
            scheduleRanges.some((sr) => isBetweenRange(t, sr)),
        );
    }
    const isAvailableAtHourOfDay = (
        unavailabilities: { start: dayjs.Dayjs; end: dayjs.Dayjs }[],
        hourOfDay: number,
        resourceId: string,
    ): boolean => {
        const srs = props.resourceScheduleRanges?.(resourceId);
        const isAvailableByScheduleRange =
            // If no schedule, means allow all times
            !srs?.length || srs.some((sr) => isBetweenRange(hourOfDay, sr));
        return (
            isAvailableByScheduleRange &&
            !unavailabilities.some((st) => isBetween(hourOfDay, st))
        );
    };
    return (
        <div
            style={{
                gridTemplateColumns: `100px repeat(${times.length}, 30px)`,
                gridTemplateRows: `minmax(32px, min-content) repeat(${schedule.length}, 50px)`,
            }}
            css={styles.timetableWrapper}
        >
            <div key="topleftblank" css={topLeftBlankCss} />
            {times
                .filter((_, i) => i % 2 == 0)
                .map((t) => (
                    <TimeHeader key={t} time={t} />
                ))}
            {schedule.map((s) => (
                <Fragment key={s.resource.uid}>
                    <ResourceHeader
                        key={s.resource.uid + "head"}
                        css={styles.stickyLeft}
                        name={s.resource.name}
                    />
                    {times.map((t, idx, arr) => (
                        <Cell
                            key={s.resource.uid + t.toString()}
                            roundStart={
                                (isDateToday && t === roundedCurrentTime) ||
                                !idx ||
                                t - (arr[idx - 1] || 0) > 0.5 ||
                                isAvailableAtHourOfDay(
                                    s.times,
                                    arr[idx - 1] || 0,
                                    s.resource.uid,
                                )
                            }
                            roundEnd={
                                arr[idx + 1] === undefined ||
                                (arr[idx + 1] || 0) - t > 0.5 ||
                                isAvailableAtHourOfDay(
                                    s.times,
                                    arr[idx + 1] || 0,
                                    s.resource.uid,
                                )
                            }
                            isAvailable={isAvailableAtHourOfDay(
                                s.times,
                                t,
                                s.resource.uid,
                            )}
                            hasElapsed={isDateToday && t < roundedCurrentTime}
                        />
                    ))}
                </Fragment>
            ))}
        </div>
    );
};
TimeTable.displayName = "TimeTable";

const timeHeaderCss = css([
    styles.solidBorder,
    styles.borderLeftNone,
    styles.stickyTop,
    styles.bgGrey,
    {
        gridColumnEnd: "span 2",
        textAlign: "center",
        padding: 4,
    },
]);
const TimeHeader = memo<TimeHeaderProps>(({ time }) => (
    <div css={timeHeaderCss}>
        {time % 12 || "12"}
        {time / 12 >= 1 ? "pm" : "am"}
    </div>
));
TimeHeader.displayName = "TimeHeader";

const isBetweenRange = (
    time: number,
    range: { start: number; end: number },
): boolean => {
    const { start, end } = range;
    if (start < end) return start <= time && time < end;
    if (start > end) return start <= time || time < end;
    // if startHour === endHour, don't filter because it means all start times available
    return true;
};

export { TimeTable };
