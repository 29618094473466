import { colours } from "@/config/theme";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ExpandingFormItem = ({
    title,
    value,
    children,
    expanded,
    onClick,
    className,
    expandedHeight = 200,
}: {
    title: React.ReactNode;
    value?: string;
    children: React.ReactNode;
    expanded: boolean;
    onClick: () => void;
    className?: string;
    expandedHeight?: number;
}): JSX.Element => {
    const [render, setRender] = useState(expanded);
    useEffect(() => {
        if (!expanded) {
            const t = setTimeout(() => {
                setRender(false);
            }, 300);
            return () => clearTimeout(t);
        } else {
            setRender(true);
        }
    }, [expanded]);
    return (
        <div
            css={{
                minHeight: 50,
                maxWidth: 800,
                width: "calc(100% - 32px)",
                padding: 12,
                margin: "0 16px",
                borderBottom: "1px solid #d9d9d9",
                overflow: "auto",
            }}
            className={className}
        >
            <div
                css={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    cursor: "pointer",
                }}
                onClick={onClick}
            >
                {title}
                <div css={{ color: !value ? "grey" : colours.textPrimary }}>
                    {value ?? "Select"}
                </div>
            </div>
            <div
                css={[
                    {
                        maxHeight: 0,
                        overflow: "hidden",
                        transition: "300ms",
                    },
                    expanded && {
                        marginTop: 8,
                        maxHeight: expandedHeight,
                        overflow: "auto",
                    },
                ]}
            >
                {render && children}
            </div>
        </div>
    );
};

type SelectTitleProps = { selectType: "category" | "date" };
const SelectTitle = React.memo<SelectTitleProps>(({ selectType }) => {
    const { t } = useTranslation("common");
    return (
        <div css={{ display: "flex", gap: 8, alignItems: "center" }}>
            {selectType == "category" ? <CategoryIcon /> : <CalendarIcon />}
            <div>
                {selectType == "category"
                    ? t("category", { defaultValue: "Category" })
                    : t("date", { defaultValue: "Date" })}
            </div>
        </div>
    );
});
SelectTitle.displayName = "SelectTitle";

const CategoryIcon = React.memo(() => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="25"
        height="25"
        fill="none"
        viewBox="0 0 26 26"
    >
        <path
            fill="#232A92"
            d="m7.3 6.2-.3 1c.5 0 1 .2 1.5.4.3-1 .8-2 1.5-2.9l5 5.1-.9 1 1.2 1 .9-.9 5 5.1a8 8 0 0 1-2.8 1.5l.5 1.5a9.6 9.6 0 0 0 6.6-11.7A9.6 9.6 0 0 0 7.3 6.2Zm16.3.6a3.2 3.2 0 0 1-3.4.1l2.2-2.2a8 8 0 0 1 1.2 2.1Zm-4.5 1.3c1.5 1 3.4 1.1 5 .3a8 8 0 0 1-1.7 6.5l-5-5.1L19 8.1Zm-1.2-1.2-1.7 1.8-5-5.1a8 8 0 0 1 6.4-1.7 4.8 4.8 0 0 0 .3 5Zm1.2-1.1a3.2 3.2 0 0 1 0-3.4 8 8 0 0 1 2.2 1.2L19 5.8Zm-12.3 9a.8.8 0 0 1 1.2 0l3.2 3.2a.8.8 0 0 1-1.2 1.2L6.8 16a.8.8 0 0 1 0-1.2ZM.3 11.2A2.7 2.7 0 0 1 3.5 8c1.5.3 3.3.8 5.1 1.5 1.8.7 3.7 1.7 5 3 1.4 1.4 2.3 3.2 3 5 .8 1.8 1.2 3.7 1.5 5.1a2.7 2.7 0 0 1-3.3 3.3C12 25 7.6 23.8 5 21c-2.6-2.6-4-7-4.6-9.9Zm2.9-1.7c-.9-.2-1.6.5-1.4 1.4.7 2.8 2 6.8 4.3 9.1s6.3 3.6 9 4.2c1 .2 1.6-.5 1.5-1.4a29 29 0 0 0-1.4-4.8c-.7-1.7-1.6-3.3-2.7-4.4-1.2-1.1-2.8-2-4.5-2.7-1.7-.7-3.4-1.1-4.8-1.4Z"
        />
    </svg>
));
CategoryIcon.displayName = "CategoryIcon";

const CalendarIcon = React.memo(() => (
    <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" fill="none">
        <path
            fill="#232A92"
            d="M19.48 2.5h-4.1V.92a.2.2 0 0 0-.2-.2h-1.36a.2.2 0 0 0-.2.2v1.56H7.38V.93a.2.2 0 0 0-.2-.2H5.81a.2.2 0 0 0-.2.2v1.56h-4.1a.78.78 0 0 0-.78.78v16.21c0 .44.35.79.79.79h17.96c.44 0 .79-.35.79-.79V3.28a.78.78 0 0 0-.79-.79Zm-.97 16H2.49V9.24h16.02v9.28ZM2.49 7.58V4.25h3.13v1.17c0 .1.09.2.2.2h1.36c.1 0 .2-.1.2-.2V4.25h6.25v1.17c0 .1.08.2.19.2h1.37c.1 0 .2-.1.2-.2V4.25h3.12v3.32H2.49Z"
        />
    </svg>
));
CalendarIcon.displayName = "CalendarIcon";

export { ExpandingFormItem, SelectTitle };
