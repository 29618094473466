import type React from "react";
import type { ReactNode } from "react";
import { css } from "@emotion/react";

import { styles } from "./styles";

export const style = css([
    styles.solidBorder,
    styles.borderTopNone,
    styles.borderLeftNone,
    { scrollSnapAlign: "start" },
]);

const pillCss = css({
    "--pillMargin": "2px",
    "--cellBorderSize": "0.8px",
    "--pillBorderRadius": "8px",
    backgroundColor: "#122DB5",
});
const pillStart: React.CSSProperties = {
    width: "calc(100% - 2 * var(--cellBorderSize))",
    marginLeft: "var(--pillMargin)",
    borderBottomLeftRadius: "var(--pillBorderRadius)",
    borderTopLeftRadius: "var(--pillBorderRadius)",
};
const pillEnd: React.CSSProperties = {
    width: "calc(100% - 2 * var(--cellBorderSize))",
    marginRight: "var(--pillMargin)",
    borderTopRightRadius: "var(--pillBorderRadius)",
    borderBottomRightRadius: "var(--pillBorderRadius)",
};
const pillStartVertical: React.CSSProperties = {
    height: "calc(100% - 2 * var(--cellBorderSize))",
    marginTop: "var(--pillMargin)",
    borderTopRightRadius: "var(--pillBorderRadius)",
    borderTopLeftRadius: "var(--pillBorderRadius)",
};
const pillEndVertical: React.CSSProperties = {
    height: "calc(100% - 2 * var(--cellBorderSize))",
    marginBottom: "var(--pillMargin)",
    borderBottomRightRadius: "var(--pillBorderRadius)",
    borderBottomLeftRadius: "var(--pillBorderRadius)",
};
const full = "100%";
const padded = "calc(100% - 2 * var(--pillMargin) + var(--cellBorderSize))";

type CellProps = {
    vertical?: boolean;
    isAvailable: boolean;
    hasElapsed?: boolean;
    roundStart?: boolean;
    roundEnd?: boolean;
    children?: ReactNode;
};
const Cell = ({
    vertical = false,
    isAvailable,
    hasElapsed = false,
    roundStart = false,
    roundEnd = false,
    children,
}: CellProps): JSX.Element => {
    let child = children;
    const cellStyle: React.CSSProperties = {};
    if (!isAvailable && !hasElapsed) {
        const startStyle = vertical ? pillStartVertical : pillStart;
        const endStyle = vertical ? pillEndVertical : pillEnd;
        const pillStyle: React.CSSProperties = {
            height: vertical ? full : padded,
            width: vertical ? padded : full,
            margin: vertical ? "0 var(--pillMargin)" : "var(--pillMargin) 0",
            ...(roundStart && startStyle),
            ...(roundEnd && endStyle),
        };
        child = (
            <div style={pillStyle} css={pillCss}>
                {child}
            </div>
        );
        if (!roundEnd) {
            if (vertical) cellStyle.borderBottom = "none";
            else cellStyle.borderRight = "none";
        }
    }
    return (
        <div
            style={cellStyle}
            css={[style, hasElapsed ? styles.bgGrey : styles.bgWhite]}
        >
            {child}
        </div>
    );
};
Cell.displayName = "Cell";
export { Cell };
