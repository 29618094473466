import * as Dialog from "@radix-ui/react-dialog";
import { AvailabilityView } from "@/components/AvailabilitySchedule/v2";
import { useMemo, useState } from "react";
import { type NextRouter, useRouter } from "next/router";
import { Button } from "./Button";
import { CloseUnfilled, SearchFilled } from "./Icons";
import { useFlag } from "@unleash/proxy-client-react";
import { useTranslation } from "react-i18next";
import dayjs from "@/lib/dayjs";
import { Loader } from "./Loader";
import { AvailabilitySchedule } from "./AvailabilitySchedule";
import { byCategoryImportance, isUniqueBy } from "@/utils/index";
import { useQuery } from "@apollo/client";
import { graphql } from "@/lib/gql";
import { formatISO } from "date-fns";

export type ViewLiveAvailabilityButtonProps = {
    org: { uid: string; name: string; isCourtsitePartner: boolean };
};

export const ViewLiveAvailabilityButton = ({
    org,
}: ViewLiveAvailabilityButtonProps): JSX.Element => {
    const { t } = useTranslation("components/ViewLiveAvailability");
    const [open, setOpen] = useState(false);
    const router = useRouter();
    const isNew = useFlag("feature.liveAvailabilityRedesign");

    let child = (
        <>
            <div className="flex-0 flex justify-between border-0 border-b border-solid border-blue-grey-200 p-4">
                <Dialog.Title className="typography-h3 font-bold text-blue-grey-900">
                    {org.name}
                </Dialog.Title>
                <Dialog.Close
                    aria-label="Close"
                    className="cursor-pointer border-none bg-transparent"
                >
                    <CloseUnfilled />
                </Dialog.Close>
            </div>
            <AvailabilityView
                tenantId={org.uid}
                onSubmit={(v) => {
                    setOpen(false);
                    router.push({
                        pathname: "/centre/[orgName]/[orgID]/select",
                        query: {
                            orgName: org.name,
                            orgID: org.uid,
                            categoryId: v.categoryId,
                            date: formatISO(v.date, { representation: "date" }),
                        },
                    });
                }}
            />
        </>
    );
    if (!isNew) {
        child = (
            <ViewAvailabilityOld
                orgID={org.uid}
                orgName={org.name}
                setOpen={setOpen}
                router={router}
            />
        );
    }

    if (!org.isCourtsitePartner) return <></>;
    return (
        <Dialog.Root open={open} onOpenChange={setOpen}>
            <Dialog.Trigger asChild>
                <Button
                    className="flex w-full gap-2 rounded p-3"
                    variant="outlined"
                    size="lg"
                >
                    <SearchFilled />
                    <div className="typography-sub text-wrap text-start font-bold capitalize">
                        {t("availability", "Availability")}
                    </div>
                </Button>
            </Dialog.Trigger>
            <Dialog.Portal>
                <Dialog.Overlay className="fixed inset-0 z-20 bg-black/40" />
                <Dialog.Content
                    aria-describedby={undefined}
                    className="fixed inset-0 z-20 flex h-dvh w-dvw flex-col gap-4 overflow-y-auto bg-white lg:inset-1/2 lg:h-max lg:max-h-[80%] lg:max-w-[628px] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:rounded-4xl"
                >
                    {child}
                </Dialog.Content>
            </Dialog.Portal>
        </Dialog.Root>
    );
};

type ViewAvailabilityButtonProps = {
    orgID: string;
    orgName: string;
    router: NextRouter;
    setOpen: (v: boolean) => void;
};

const ViewAvailabilityOld = ({
    orgID,
    orgName,
    router,
    setOpen,
}: ViewAvailabilityButtonProps): JSX.Element => {
    const { t } = useTranslation("common");
    const { data, loading } = useQuery(query, {
        variables: { tenantId: orgID },
        fetchPolicy: "cache-and-network",
    });

    type SubmitValues = { categoryId?: string; date?: dayjs.Dayjs };
    const handleViewAvailabilitySubmit = (values: SubmitValues): void => {
        setOpen(false);
        router.push({
            pathname: "/centre/[orgName]/[orgID]/select",
            query: {
                orgName: orgName,
                orgID: orgID,
                date: values.date
                    ? values.date.tz().startOf("d").format("YYYY-MM-DD")
                    : undefined,
                categoryId: values.categoryId,
            },
        });
    };

    const onlineServices = useMemo(
        () =>
            data?.onlineServices.filter(
                ({ uid }) =>
                    !data.serviceTags.some((st) => st.serviceId === uid) ||
                    data.myMemberServiceIds.includes(uid),
            ) ?? [],
        [data],
    );
    if (loading) return <Loader loading />;

    const categories = onlineServices
        .map((s) => s.category)
        .filter(isUniqueBy((v) => v?.uid))
        .map((c) => ({
            uid: c?.uid ?? "",
            name: c?.name ?? "",
            metadata: c?.metadata ?? "",
            isAllDailyModeServices: onlineServices
                .filter((s) => s.category?.uid === c?.uid)
                .every((s) => s.serviceMode === "DAILY_SERVICE"),
        }))
        .sort(byCategoryImportance);

    return (
        <div className="flex h-full flex-col items-center lg:min-h-[700px]">
            <AvailabilitySchedule
                tenantId={orgID}
                submitButtonText={t("book_now", "Book Now")}
                onSubmit={handleViewAvailabilitySubmit}
                scheduleRanges={(uid) =>
                    onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => {
                            const m = JSON.parse(s.metadata);
                            return {
                                start:
                                    parseInt(m.bookingSelectorStart ?? "0") %
                                    24,
                                end:
                                    parseInt(m.bookingSelectorEnd ?? "24") % 24,
                            };
                        })
                }
                unavailableAfterDate={(uid) => {
                    const c = onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => s.bookingWindow ?? 0);
                    if (c.some((w) => !w)) {
                        return undefined;
                    }
                    const m = Math.max(...c);
                    return dayjs()
                        .tz()
                        .add(m * 7, "d")
                        .startOf("d");
                }}
                unavailableBeforeDate={(uid) => {
                    const c = onlineServices
                        .filter((s) => s.category?.uid === uid)
                        .map((s) => s.minBookingWindowMinutes);
                    if (!c.every((w): w is number => !w)) {
                        return dayjs().tz().startOf("d");
                    }
                    const m = Math.min(...c);
                    return dayjs().add(m, "m").startOf("d");
                }}
                categories={categories}
                resourceScheduleRanges={(uid) =>
                    onlineServices
                        .filter((s) =>
                            s.resources.some(
                                (sr) => !sr.archived && sr.resourceId === uid,
                            ),
                        )
                        .map((s) => {
                            const m = JSON.parse(s.metadata);
                            return {
                                start:
                                    parseInt(m.bookingSelectorStart ?? "0") %
                                    24,
                                end:
                                    parseInt(m.bookingSelectorEnd ?? "24") % 24,
                            };
                        })
                }
            />
        </div>
    );
};

const query = graphql(`
    query viewLiveAvailability($tenantId: ID!) {
        onlineServices(tenantId: $tenantId) {
            uid
            name
            minBookingWindowMinutes
            bookingWindow
            disallowBookingGap
            metadata
            serviceMode
            category {
                uid
                name
                metadata
            }
            resources {
                uid
                resourceId
                archived
            }
            customDurationOptions
        }
        addOns(tenantId: $tenantId) {
            uid
            name
            price
            isInStock
        }
        myMemberServiceIds(tenantId: $tenantId)
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
    }
`);
