import { gql, QueryHookOptions, useQuery } from "@apollo/client";
import dayjs from "@/lib/dayjs";
import { useMemo } from "react";
import { isUniqueBy } from "@/utils/index";

type DateRangeFilter = { startDt: string; endDt: string };
type UnavailableSlotsRequest = {
    categoryId: string;
    dtRange: DateRangeFilter;
    tenantId: string;
};
type UnavailableSlotsData = {
    unavailableResourceSlotsBetween: {
        tenantId: string;
        resourceId: string;
        startDt: string;
        endDt: string;
    }[];
    onlineServices: {
        uid: string;
        resources: {
            uid: string;
            archived?: string;
            resource: { uid: string; name: string };
        }[];
    }[];
};
const unavailableSlots = gql`
    query unavailableSlots(
        $categoryId: ID!
        $dtRange: DateRangeFilter!
        $tenantId: ID!
    ) {
        unavailableResourceSlotsBetween(
            categoryId: $categoryId
            dtRange: $dtRange
            tenantId: $tenantId
        ) {
            tenantId
            resourceId
            startDt
            endDt
        }
        onlineServices(
            tenantId: $tenantId
            filter: { byCategoryId: $categoryId }
        ) {
            uid
            resources {
                uid
                archived
                resource {
                    uid
                    name
                }
            }
        }
    }
`;
type ResourceUnavailabilities = {
    resource: { uid: string; name: string };
    times: { start: dayjs.Dayjs; end: dayjs.Dayjs }[];
};
export const useResourceUnavailabilities = (
    tenantId: string | undefined,
    categoryId: string | undefined,
    date: dayjs.Dayjs | undefined,
    scheduleRanges: { start: number; end: number }[],
): ResourceUnavailabilities[] | undefined => {
    let startDt = date;
    let endDt = date;
    if (date) {
        const filterRange = getFilterRangeFromScheduleRanges(scheduleRanges);
        startDt = date.tz().startOf("d").hour(filterRange.start);
        endDt = startDt.add(filterRange.end - filterRange.start, "h");
    }
    const opts: QueryHookOptions<
        UnavailableSlotsData,
        UnavailableSlotsRequest
    > =
        !tenantId || !categoryId || !startDt || !endDt
            ? { skip: true }
            : {
                  variables: {
                      tenantId,
                      categoryId,
                      dtRange: {
                          // Add -1 hour for bookings that possibly clash with disallowed booking gap range
                          startDt: startDt.add(-1, "h").toISOString(),
                          // Add 6 hours because we need to make sure selecting 11:30pm 6 hours also does not clash
                          // Add 1 hour on top of that for bookings that possibly clash with disallowed booking gap range
                          endDt: endDt.add(7, "h").toISOString(),
                      },
                  },
                  fetchPolicy: "network-only",
              };
    const { data } = useQuery<UnavailableSlotsData>(unavailableSlots, opts);
    // TODO: online services could probably rely on cache instead always from network
    // TODO: this assumes that there is only one service mode per resource;
    return useMemo(() => {
        return data?.onlineServices
            .flatMap((s) =>
                s.resources.filter((r) => !r.archived).map((r) => r.resource),
            )
            .filter(isUniqueBy((r) => r.uid))
            .sort((a, b) =>
                a.name.localeCompare(b.name, undefined, { numeric: true }),
            )
            .map((resource) => ({
                resource,
                times: data.unavailableResourceSlotsBetween
                    .filter((ur) => ur.resourceId === resource.uid)
                    .map((ur) => ({
                        start: dayjs(ur.startDt).tz(),
                        end: dayjs(ur.endDt).tz(),
                    })),
            }));
    }, [data]);
};

const getFilterRangeFromScheduleRanges = (
    srs: { start: number; end: number }[],
): { start: number; end: number } => {
    if (!srs.length || srs.some((sr) => sr.start >= sr.end))
        return { start: 0, end: 24 };
    return {
        start: Math.min(...srs.map((sr) => sr.start)),
        end: Math.max(...srs.map((sr) => sr.end)),
    };
};
