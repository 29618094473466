import type { SVGProps } from "./types";

export const LinkFilled = (props: SVGProps): JSX.Element => (
    <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12.2625 5.26256C12.946 4.57915 14.054 4.57915 14.7374 5.26256C15.4208 5.94598 15.4208 7.05402 14.7374 7.73744L12.1124 10.3624C11.429 11.0459 10.321 11.0459 9.63755 10.3624C9.29584 10.0207 8.74182 10.0207 8.40011 10.3624C8.0584 10.7041 8.0584 11.2582 8.40011 11.5999C9.76694 12.9667 11.983 12.9667 13.3499 11.5999L15.9749 8.97487C17.3417 7.60804 17.3417 5.39196 15.9749 4.02513C14.608 2.65829 12.3919 2.65829 11.0251 4.02513L9.71261 5.33763C9.3709 5.67933 9.3709 6.23335 9.71261 6.57506C10.0543 6.91677 10.6083 6.91677 10.95 6.57506L12.2625 5.26256Z"
            fill="currentColor"
        />
        <path
            d="M7.88756 9.63756C8.57098 8.95414 9.67902 8.95414 10.3624 9.63756C10.7041 9.97927 11.2582 9.97927 11.5999 9.63756C11.9416 9.29585 11.9416 8.74183 11.5999 8.40013C10.233 7.03329 8.01696 7.03329 6.65013 8.40013L4.02513 11.0251C2.65829 12.392 2.65829 14.608 4.02513 15.9749C5.39196 17.3417 7.60804 17.3417 8.97487 15.9749L10.2874 14.6624C10.6291 14.3207 10.6291 13.7666 10.2874 13.4249C9.94566 13.0832 9.39164 13.0832 9.04994 13.4249L7.73744 14.7374C7.05402 15.4209 5.94598 15.4209 5.26256 14.7374C4.57915 14.054 4.57915 12.946 5.26256 12.2626L7.88756 9.63756Z"
            fill="currentColor"
        />
    </svg>
);
