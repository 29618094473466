import React, { useState, memo, ReactNode } from "react";
import { Button } from "@/components/Button";
import { useResourceUnavailabilities } from "./useResourceUnavailabilities";
import { ExpandingFormItem, SelectTitle } from "./ExpandingFormItem";
import { TimeTable } from "./TimeTable";
import dayjs from "@/lib/dayjs";
import { useTranslation } from "react-i18next";
import { DailyTimeTable, useServiceUnavailabilities } from "./DailyTimeTable";
import { Loader } from "@/components/Loader";
import { Calendar } from "@/components/CalendarNew";
import { cva, VariantProps } from "class-variance-authority";
import { overrideDateTimezone, tzKL } from "@/lib/date-fns-util";

type SubmitValues = {
    categoryId: string | undefined;
    date: dayjs.Dayjs | undefined;
};
type HourlyAvailabilityScheduleProps = {
    orgID: string;
    category: Category;
    date: dayjs.Dayjs;
    submitButtonProps: SubmitButtonProps;
    scheduleRanges: (categoryId: string) => { start: number; end: number }[];
    resourceScheduleRanges: (
        resourceId: string,
    ) => { start: number; end: number }[];
};
// TODO: Add unavailabilities by min booking window
type AvailabilityScheduleProps = {
    tenantId: string;
    initialCategoryId?: string;
    initialDate?: dayjs.Dayjs;
    submitButtonText: string;
    onSubmit?: (values: SubmitValues) => void;
    unavailableAfterDate: (categoryId: string) => dayjs.Dayjs | undefined;
    unavailableBeforeDate: (categoryId: string) => dayjs.Dayjs | undefined;
    scheduleRanges: (categoryId: string) => { start: number; end: number }[];
    resourceScheduleRanges: (
        resourceId: string,
    ) => { start: number; end: number }[];
    categories: Category[];
};

export const AvailabilitySchedule = ({
    tenantId,
    initialCategoryId,
    initialDate,
    submitButtonText,
    unavailableAfterDate,
    unavailableBeforeDate,
    categories,
    scheduleRanges,
    onSubmit,
    ...props
}: AvailabilityScheduleProps): JSX.Element => {
    const isSingleCategory = categories.length === 1;
    const firstCategoryId = categories[0]?.uid;
    const [categoryId, setCategoryId] = useState(
        initialCategoryId || firstCategoryId,
    );
    const [date, setDate] = useState(initialDate);
    const [categorySelectExpanded, setCategorySelectExpanded] =
        useState(!categoryId);
    const [dateSelectExpanded, setDateSelectExpanded] = useState(
        (isSingleCategory || !!categoryId) && !date,
    );
    const currentCategory = categories.find((c) => c.uid === categoryId);
    const submitButtonProps = {
        text: submitButtonText,
        onClick: () => onSubmit?.({ categoryId, date }),
    };

    let body: React.ReactNode;
    if (!isSingleCategory) {
        body = (
            <CategorySelect
                value={currentCategory}
                onChange={({ uid }) => {
                    setCategoryId(uid);
                    setCategorySelectExpanded(false);
                    let newDate = date;
                    const uad = unavailableAfterDate(uid);
                    if (newDate && uad && !newDate.isBefore(uad)) {
                        newDate = undefined;
                    }
                    const ubd = unavailableBeforeDate(uid);
                    if (newDate && ubd && newDate.isBefore(ubd)) {
                        newDate = undefined;
                    }
                    setDate(newDate);
                    setDateSelectExpanded(!newDate);
                }}
                onClick={() => {
                    setCategorySelectExpanded(!categorySelectExpanded);
                    setDateSelectExpanded(false);
                }}
                expanded={categorySelectExpanded}
                options={categories}
            />
        );
    }

    if (currentCategory) {
        let legend: React.ReactNode;
        let timetable: React.ReactNode;
        if (date) {
            legend = <Legends />;
            timetable = (
                <div className="flex w-full items-center justify-center p-8">
                    <Loader loading />
                </div>
            );

            timetable = (
                <HourlyAvailabilitySchedule
                    orgID={tenantId}
                    category={currentCategory}
                    date={date}
                    scheduleRanges={scheduleRanges}
                    resourceScheduleRanges={props.resourceScheduleRanges}
                    submitButtonProps={submitButtonProps}
                />
            );
            if (currentCategory.isAllDailyModeServices) {
                timetable = (
                    <DailyAvailabilitySchedule
                        orgID={tenantId}
                        category={currentCategory}
                        date={date}
                        submitButtonProps={submitButtonProps}
                    />
                );
            }
        }

        body = (
            <>
                {body}
                <DateSelect
                    expandedHeight={390}
                    expanded={dateSelectExpanded}
                    value={date}
                    unavailableAfterDate={unavailableAfterDate(
                        currentCategory.uid,
                    )}
                    unavailableBeforeDate={unavailableBeforeDate(
                        currentCategory.uid,
                    )}
                    onChange={(d) => {
                        setDate(d);
                        setDateSelectExpanded(false);
                    }}
                    onClick={() => {
                        setCategorySelectExpanded(false);
                        setDateSelectExpanded(!dateSelectExpanded);
                    }}
                />
                {legend}
                {timetable}
            </>
        );
    }

    return <>{body}</>;
};

const HourlyAvailabilitySchedule = ({
    orgID,
    category,
    date,
    scheduleRanges,
    submitButtonProps,
    ...props
}: HourlyAvailabilityScheduleProps): JSX.Element => {
    const schedule = useResourceUnavailabilities(
        orgID,
        category.uid,
        date,
        scheduleRanges(category.uid),
    );

    if (!schedule)
        return (
            <div className="flex w-full items-center justify-center p-8">
                <Loader loading />
            </div>
        );
    const { text, onClick } = submitButtonProps;
    return (
        <>
            <TimeTable
                date={date}
                schedule={schedule}
                scheduleRanges={scheduleRanges(category.uid)}
                resourceScheduleRanges={props.resourceScheduleRanges}
            />
            <SubmitButton text={text} onClick={onClick} />
        </>
    );
};

const DailyAvailabilitySchedule = ({
    orgID,
    category,
    date,
    submitButtonProps,
}: {
    orgID: string;
    category: Category;
    date: dayjs.Dayjs;
    submitButtonProps: SubmitButtonProps;
}): JSX.Element => {
    const schedule = useServiceUnavailabilities(
        orgID,
        category.uid,
        getDailyScheduleRange(date),
    );

    if (!schedule)
        return (
            <div className="flex w-full items-center justify-center p-8">
                <Loader loading />
            </div>
        );

    const { text, onClick } = submitButtonProps;
    return (
        <>
            <DailyTimeTable
                date={date}
                schedule={schedule}
                scheduleRange={getDailyScheduleRange(date)}
            />
            <SubmitButton text={text} onClick={onClick} />
        </>
    );
};
const getDailyScheduleRange = (
    date: dayjs.Dayjs,
): { start: dayjs.Dayjs; end: dayjs.Dayjs } => {
    const d = date.startOf("d");
    return {
        start: d.add(-1, "d"),
        end: d.add(10, "d"),
    };
};

type Category = { uid: string; name: string; isAllDailyModeServices: boolean };
type CategorySelectProps = {
    onChange: (value: Category) => void;
    value?: Category;
    options: Category[];
    expanded: boolean;
    onClick: () => void;
    className?: string;
};
const CategorySelect = ({
    onChange,
    value,
    options,
    expanded,
    onClick,
    className,
}: CategorySelectProps): JSX.Element => (
    <ExpandingFormItem
        title={<SelectTitle selectType="category" />}
        onClick={onClick}
        value={value?.name}
        expanded={expanded}
        className={className}
    >
        {options.map((o) => (
            <div
                key={o.uid}
                onClick={() => onChange(o)}
                className="cursor-pointer px-4 py-2 hover:bg-primary-50"
            >
                {o.name}
            </div>
        ))}
    </ExpandingFormItem>
);

type DateSelectProps = {
    value?: dayjs.Dayjs;
    unavailableAfterDate?: dayjs.Dayjs;
    unavailableBeforeDate?: dayjs.Dayjs;
    onChange: (date: dayjs.Dayjs) => void;
    onClick: () => void;
    expanded: boolean;
    expandedHeight: number;
    className?: string;
};
const DateSelect = ({
    value,
    unavailableAfterDate,
    unavailableBeforeDate,
    onChange,
    onClick,
    expanded,
    expandedHeight,
    className,
}: DateSelectProps): JSX.Element => {
    const isDisabledDate = (day: Date): boolean => {
        // This function is adapted from the function in lib/bookingWindow.ts. Since the parent
        // component will eventually be deprecated, there's not much reason to unify the logic for
        // use here and in lib/bookingWindow.ts.
        const d = dayjs.tz(day);
        const now = dayjs().tz();
        const disabledBefore =
            unavailableBeforeDate ?? now.startOf("d").subtract(20, "m");
        const disabledAfter =
            unavailableAfterDate ?? now.startOf("d").add(1, "y");
        return d.isBefore(disabledBefore) || d.isSameOrAfter(disabledAfter);
    };
    const handleSelect = (d: Date | undefined): void => {
        if (!d || isDisabledDate(d)) return;
        const date = overrideDateTimezone(d, tzKL);
        onChange(dayjs.tz(date));
    };
    return (
        <ExpandingFormItem
            title={<SelectTitle selectType="date" />}
            onClick={onClick}
            value={value?.format("DD MMMM YYYY")}
            expanded={expanded}
            expandedHeight={expandedHeight}
            className={className}
        >
            <div className="flex justify-center">
                <Calendar
                    mode="single"
                    selected={
                        value &&
                        new Date(
                            value.year(),
                            value.month(),
                            value.date(),
                            value.hour(),
                            value.minute(),
                            value.second(),
                            value.millisecond(),
                        )
                    }
                    className="max-w-[400px]"
                    disabled={isDisabledDate}
                    onSelect={handleSelect}
                    fromMonth={unavailableBeforeDate?.toDate() ?? new Date()}
                    toMonth={unavailableAfterDate?.toDate()}
                />
            </div>
        </ExpandingFormItem>
    );
};

const Legends = memo(() => {
    const { t, ready } = useTranslation("components/AvailabilitySchedule");
    if (!ready) return <div />;
    return (
        <div className="m-3 flex gap-4 overflow-hidden">
            <AvailabilityLegend
                text={t("available", "Available")}
                color="white"
            />
            <AvailabilityLegend
                text={t("unavailable", "Unavailable")}
                color="blue"
                border="none"
            />
            <AvailabilityLegend text={t("elapsed", "Elapsed")} color="grey" />
        </div>
    );
});
Legends.displayName = "Legends";

const legendVariants = cva("size-[10px] border border-solid", {
    variants: {
        color: {
            white: "bg-white",
            blue: "bg-[#122DB5]",
            grey: "bg-[#F5F5F5]",
        },
        border: {
            none: "border-transparent",
            normal: "border-blue-grey-100",
        },
    },
    defaultVariants: { border: "normal" },
});
type AvailabilityLegendProps = VariantProps<typeof legendVariants> & {
    text: ReactNode;
};
const AvailabilityLegend = React.memo<AvailabilityLegendProps>(
    ({ text, ...props }): JSX.Element => (
        <span className="flex items-center gap-2">
            <div className={legendVariants(props)} />
            {text}
        </span>
    ),
);
AvailabilityLegend.displayName = "AvailabilityLegend";

type SubmitButtonProps = { text: string; onClick: () => void };
const SubmitButton = ({ text, onClick }: SubmitButtonProps): JSX.Element => (
    <div className="z-[1] w-full rounded-b-lg bg-white p-6 shadow-sm">
        <Button className="w-full" onClick={onClick}>
            {text}
        </Button>
    </div>
);
