import { useQuery } from "@apollo/client";
import { cx } from "class-variance-authority";
import { Button } from "@/components/Button";
import { DatePicker } from "@/components/CalendarNew";
import { DropdownSelect } from "@/components/DropdownSelect";
import { Input } from "@/components/Forms";
import { CalendarUnfilled } from "@/components/Icons";
import * as Popover from "@radix-ui/react-popover";
import * as ToggleGroup from "@radix-ui/react-toggle-group";
import {
    addDays,
    addHours,
    addMinutes,
    addWeeks,
    areIntervalsOverlapping,
    format,
    isBefore,
    isEqual,
    isWithinInterval,
    parseISO,
    subHours,
    compareDesc,
    startOfToday,
} from "date-fns";
import { useAuth } from "@/lib/firebase/hooks";
import { graphql } from "@/lib/gql";
import { DateRangeFilter } from "@/lib/gql/graphql";
import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useCart } from "@/lib/cart";
import dayjs from "@/lib/dayjs";
import { CloseUnfilled } from "@/components/Icons/Close";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { tz, TZDate } from "@date-fns/tz";
import {
    overrideDateTimezone,
    stripDateTimezone,
    tzKL,
} from "@/lib/date-fns-util";
import { disabledDateByBookingWindowHandler } from "@/lib/bookingWindow";

const query = graphql(`
    query pelangganAvailabilityView($tenantId: ID!, $isLoggedIn: Boolean!) {
        categories {
            uid
            name
        }
        onlineServices(tenantId: $tenantId) {
            uid
            name
            serviceMode
            bookingWindow
            metadata
            resources {
                uid
                archived
                resource {
                    uid
                    name
                }
            }
            serviceCategory {
                uid
                categoryId
            }
        }
        serviceTags(tenantId: $tenantId) {
            serviceId
        }
        myMemberServiceIds(tenantId: $tenantId) @include(if: $isLoggedIn)
    }
`);
const unavailabilityQuery = graphql(`
    query pelangganAvailabilityViewUnavailabilities(
        $tenantId: ID!
        $categoryId: ID!
        $dtRange: DateRangeFilter!
    ) {
        unavailableResourceSlotsBetween(
            categoryId: $categoryId
            dtRange: $dtRange
            tenantId: $tenantId
        ) {
            resourceId
            startDt
            endDt
        }
    }
`);
// TODO: selector form styling
// TODO: custom duration handling
// TODO: daily service handling
type TFunc = TFunction<"components/AvailabilitySchedule">;
type AvailabilityViewValues = { date: Date; categoryId: string };
type AvailabilityViewProps = {
    tenantId: string;
    onSubmit: (v: AvailabilityViewValues) => void;
    inTZ?: string;
};
export const AvailabilityView = ({
    tenantId,
    onSubmit,
    inTZ = tzKL,
}: AvailabilityViewProps): JSX.Element => {
    const { user } = useAuth();
    const [now] = useState(TZDate.tz(inTZ));
    const { state } = useCart();
    const [categoryId, setCategoryId] = useState("");
    const [serviceId, setServiceId] = useState("");
    const [date, setDate] = useState<Date>();
    const { data } = useQuery(query, {
        variables: { tenantId, isLoggedIn: !!user },
        fetchPolicy: "cache-and-network",
    });

    const { t } = useTranslation(["components/AvailabilitySchedule", "common"]);
    const selectedService = data?.onlineServices.find(isUid(serviceId));
    const dtRange = serviceUnavailabilityDtRange(selectedService, date);
    const schedDtRange = getScheduleDtRange(selectedService, date);

    const { data: unavailabilitiesData } = useQuery(unavailabilityQuery, {
        variables: { categoryId, tenantId, dtRange: dtRangeISOString(dtRange) },
        skip: !categoryId || !dtRange,
        fetchPolicy: "cache-and-network",
    });
    const userServices = data?.onlineServices.filter(
        (s) =>
            s.serviceMode === "HOURLY_SERVICE" &&
            (data.myMemberServiceIds?.includes(s.uid) ||
                !data.serviceTags.some((st) => st.serviceId === s.uid)),
    );
    const categories = data?.categories.filter((c) =>
        userServices?.some(isCategory(c.uid)),
    );
    const services = userServices?.filter(isCategory(categoryId));

    const unavailabilities =
        unavailabilitiesData?.unavailableResourceSlotsBetween?.map((ua) => ({
            resourceId: ua.resourceId,
            startDt: parseISO(ua.startDt),
            endDt: parseISO(ua.endDt),
        }));

    const resources = selectedService?.resources
        .filter(isNotArchived)
        .map((r) => r.resource)
        .sort(byName);

    const maxDate = selectedService?.bookingWindow
        ? addWeeks(
              startOfToday({ in: tz(inTZ) }),
              selectedService.bookingWindow,
          )
        : undefined;
    const isWithinMaxDate =
        !maxDate || (!!dtRange && isBefore(dtRange.startDt, maxDate));
    const cart = state.carts.find((c) => c.tenantId === tenantId);
    const lastCartSlotDate = cart?.slots
        .map((v) => v.timeDateFns)
        .sort(compareDesc)[0];

    return (
        <div role="search" className="flex flex-1 flex-col gap-2 p-4 pb-0">
            <div>
                <DropdownSelect
                    value={categoryId}
                    onValueChange={(id) => {
                        setCategoryId(id);
                        setServiceId("");
                        setDate(undefined);
                    }}
                    options={categories?.map(toOpt)}
                    placeholder={t("selectSport", "Select sport")}
                />
            </div>
            <div>
                <DropdownSelect
                    disabled={!categoryId}
                    value={serviceId}
                    onValueChange={(id) => {
                        setServiceId(id);
                        setDate(undefined);
                    }}
                    options={services?.map(toOpt)}
                    placeholder={t("selectService", "Select service")}
                />
            </div>
            <div>
                <DatePicker
                    disabled={disabledDateByBookingWindowHandler({
                        maxWeeks: selectedService?.bookingWindow,
                    })}
                    // This is to "trick" DayPicker so that it shows the right date selected regardless of device timezone
                    selected={date && stripDateTimezone(date)}
                    onSelect={(day) => {
                        if (!day) return;
                        // This means that we will be dealing with midnight in the centre timezone
                        setDate(overrideDateTimezone(day, inTZ));
                    }}
                >
                    <Input
                        disabled={!serviceId}
                        placeholder={t("common:date", "Date")}
                        value={date ? format(date, "dd MMM yyyy, eeee") : ""}
                        suffixDiv={
                            <CalendarUnfilled className="size-5 text-blue-grey-200" />
                        }
                    />
                </DatePicker>
            </div>
            <Legend t={t} />
            {!!selectedService &&
                !!schedDtRange &&
                !!resources &&
                isWithinMaxDate && (
                    <Schedule
                        tenantId={tenantId}
                        categoryId={categoryId}
                        serviceId={selectedService.uid}
                        time={now}
                        range={schedDtRange}
                        resources={resources}
                        unavailabilities={unavailabilities}
                    />
                )}
            <div className="sticky bottom-0 bg-white p-4 pt-2">
                <Button
                    className="w-full"
                    onClick={() => {
                        let d = date;
                        if (lastCartSlotDate) {
                            d = lastCartSlotDate;
                        }
                        if (!d || !categoryId) return;
                        onSubmit({ date: d, categoryId: categoryId });
                    }}
                    disabled={!categoryId || (!date && !lastCartSlotDate)}
                >
                    {t("common:buttonBookNow", "Book Now")}
                </Button>
            </div>
        </div>
    );
};

const Legend = ({ t }: { t: TFunc }): JSX.Element => (
    <dl className="flex items-center justify-center gap-5 p-4">
        <div className="flex flex-row-reverse gap-3">
            <dt> {t("unavailable", "Unavailable")}</dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-transparent bg-blue-grey-50">
                <span className="sr-only">Grey</span>
                <CloseUnfilled className="size-3 text-blue-grey-100" />
            </dd>
        </div>
        <div className="flex flex-row-reverse gap-3">
            <dt>{t("available", "Available")}</dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-transparent bg-primary">
                <span className="sr-only">Blue</span>
            </dd>
        </div>
        <div className="flex flex-row-reverse gap-3">
            <dt>{t("selected", "Selected")}</dt>
            <dd className="box-content flex size-6 items-center justify-center rounded-full border-[0.66px] border-solid border-success-600 bg-success-100">
                <span className="sr-only">Green</span>
            </dd>
        </div>
    </dl>
);

type Unavailability = { resourceId: string; startDt: Date; endDt: Date };
type ScheduleProps = {
    tenantId: string;
    categoryId: string;
    serviceId: string;
    time: Date;
    range: DtRange;
    resources: EntityWithName[];
    unavailabilities?: Unavailability[];
};
const Schedule = (props: ScheduleProps): JSX.Element => {
    let i = props.range.startDt;
    const times: Date[] = [];
    while (isBefore(i, props.range.endDt)) {
        if (isBefore(i, props.time)) {
            i = addMinutes(i, 30);
            continue;
        }
        times.push(i);
        i = addMinutes(i, 30);
    }
    const isAvailable = (rid: string, t: Date): boolean =>
        !props.unavailabilities?.some(
            (u) => u.resourceId === rid && isWithin(t, u),
        );
    return (
        <div
            className="grid flex-1 gap-y-4 overflow-auto py-2"
            style={{
                gridTemplateColumns:
                    `[${gridH}] max-content ` +
                    times.map((t) => `[${colT(t)}] 37px`).join(" "),
                gridTemplateRows:
                    `[${gridH}] 35px ` +
                    props.resources.map((r) => `[${r.uid}] 35px`).join(" "),
            }}
        >
            {props.resources.map((r) =>
                times.map((t) => (
                    <AvailabilityCell
                        tenantId={props.tenantId}
                        categoryId={props.categoryId}
                        serviceId={props.serviceId}
                        key={`c-${r.uid}-${colT(t)}`}
                        resource={r}
                        time={t}
                        disabled={!isAvailable(r.uid, t)}
                    />
                )),
            )}
            {times.map((t) =>
                t.getMinutes() === 0 ? (
                    <div
                        key={`h-${colT(t)}`}
                        className="sticky top-0 col-span-2 flex items-center border-0 border-l-[0.5px] border-solid border-blue-grey-50 bg-white"
                        style={{ gridRow: gridH, gridColumn: colT(t) }}
                    >
                        {format(t, "ha")}
                    </div>
                ) : undefined,
            )}
            {props.resources.map((r) => (
                <div
                    key={`h-${r.uid}`}
                    className="sticky left-0 flex items-center justify-center bg-white pr-4"
                    style={{ gridColumn: gridH, gridRow: r.uid }}
                >
                    {r.name}
                </div>
            ))}
            <div
                className="sticky left-0 top-0 flex items-center justify-center bg-white"
                style={{ gridColumn: gridH, gridRow: gridH }}
            >
                <Spinner
                    className={cx(
                        "size-5",
                        !!props.unavailabilities && "hidden",
                    )}
                />
            </div>
        </div>
    );
};

const cellContainerClass =
    "all-unset group cursor-pointer border-0 border-l-[0.5px] border-solid border-blue-grey-100 px-[1px]";
type AvailabilityCellProps = {
    tenantId: string;
    categoryId: string;
    serviceId: string;
    disabled?: boolean;
    time: Date;
    resource: { uid: string; name: string };
};
const AvailabilityCell = (props: AvailabilityCellProps): JSX.Element => {
    const { state, dispatch } = useCart();
    const [open, setOpen] = useState(false);
    const { t } = useTranslation("components/AvailabilitySchedule");
    const cart = state.carts.find((c) => c.tenantId === props.tenantId);
    const matchingSlot = cart?.slots.find(
        (s) =>
            s.resourceId === props.resource.uid &&
            isWithin(props.time, {
                startDt: s.time.toDate(),
                endDt: addHours(s.time.toDate(), s.duration),
            }),
    );
    const gridStyle = {
        gridColumn: colT(props.time),
        gridRow: props.resource.uid,
    };
    if (matchingSlot) {
        return (
            <button
                className={cellContainerClass}
                style={gridStyle}
                onClick={() => {
                    dispatch({
                        type: "toggleSlot",
                        slot: matchingSlot,
                        tenantId: props.tenantId,
                    });
                }}
            >
                <div className="h-full w-full rounded-full border border-solid border-success-600 bg-success-100" />
            </button>
        );
    }
    return (
        <Popover.Root open={open} onOpenChange={setOpen} modal>
            <Popover.Trigger
                className={cellContainerClass}
                disabled={props.disabled}
                style={gridStyle}
            >
                <div className="flex h-full w-full items-center justify-center rounded-full border border-solid border-transparent bg-primary group-disabled:bg-blue-grey-50">
                    <CloseUnfilled className="hidden text-blue-grey-100 group-disabled:block" />
                </div>
            </Popover.Trigger>
            <Popover.Portal>
                <Popover.Content
                    collisionStrategy="shift"
                    side="top"
                    className="z-50 h-screen w-screen rounded-2xl border bg-primary-50 px-4 py-5 shadow-xl outline-none data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2 lg:h-auto lg:w-[343px]"
                >
                    <div className="typography-h3 mb-2 flex items-center justify-between border-0 border-b-[0.5px] border-solid border-blue-grey-200 pb-4 font-bold text-blue-grey-900">
                        {t("selectDuration", "Select duration")}
                        <Popover.Close className="all-unset">
                            <CloseUnfilled className="text-blue-grey-600" />
                        </Popover.Close>
                    </div>
                    <DurationForm
                        tenantId={props.tenantId}
                        serviceId={props.serviceId}
                        resource={props.resource}
                        time={props.time}
                        onSubmit={(v) => {
                            const slot = {
                                time: dayjs(props.time),
                                timeDateFns: props.time,
                                duration: v.duration,
                                resourceId: props.resource.uid,
                                resourceName: props.resource.name,
                                price: v.price,
                                deposit: v.deposit,
                                serviceId: props.serviceId,
                                serviceMode: "HOURLY_SERVICE" as const,
                                // TODO: handle min booking window
                                expiry: dayjs(addMinutes(props.time, 20)),
                                expiryDateFns: addMinutes(props.time, 20),
                            };
                            if (!cart) {
                                dispatch({
                                    type: "newCart",
                                    tenantId: props.tenantId,
                                });
                            }
                            if (cart?.categoryId !== props.categoryId) {
                                // TODO: warn user that cart will be cleared
                                dispatch({
                                    type: "setCartItems",
                                    tenantId: props.tenantId,
                                    categoryId: props.categoryId,
                                    addOns: [],
                                    slots: [slot],
                                });
                            } else {
                                dispatch({
                                    type: "toggleSlot",
                                    tenantId: props.tenantId,
                                    slot,
                                });
                            }
                            setOpen(false);
                        }}
                    />
                </Popover.Content>
            </Popover.Portal>
        </Popover.Root>
    );
};

type DurationFormValues = {
    duration: number;
    price: number;
    deposit: number;
    remainder: number;
};
const availabilityQuery = graphql(`
    query pelangganAvailabilityViewDurationForm(
        $req: [ServiceAvailabilityRequest!]!
        $quotReq: [QuotationRequest!]!
    ) {
        serviceAvailabilities(request: $req) {
            isAvailable
        }
        quotations(request: $quotReq) {
            deposit
            remainder
            price
            satisfiesMinimumDuration
            timeFullyAccounted
        }
    }
`);
const DurationForm = (props: {
    tenantId: string;
    serviceId: string;
    resource: { uid: string; name: string };
    time: Date;
    onSubmit: (v: DurationFormValues) => void;
}): JSX.Element => {
    const { control, handleSubmit } = useForm<{ duration: string }>();
    // TODO: handle 30 minute gap checks
    const durations = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6];
    const { state } = useCart();
    const cart = state.carts.find((c) => c.tenantId === props.tenantId);
    const { t } = useTranslation("components/AvailabilitySchedule");
    const hasCartConflict = (d: number): boolean => {
        if (!cart) return false;
        return cart.slots.some(
            (s) =>
                s.resourceId === props.resource.uid &&
                areIntervalsOverlapping(
                    {
                        start: s.time.toDate(),
                        end: addHours(s.time.toDate(), s.duration),
                    },
                    { start: props.time, end: addHours(props.time, d) },
                ),
        );
    };
    const { data, loading } = useQuery(availabilityQuery, {
        fetchPolicy: "cache-and-network",
        variables: {
            req: durations.map((d) => ({
                tenantId: props.tenantId,
                startDt: props.time.toISOString(),
                endDt: addHours(props.time, d).toISOString(),
                resourceId: props.resource.uid,
                serviceId: props.serviceId,
            })),
            quotReq: durations.map((d) => ({
                tenantId: props.tenantId,
                start: props.time.toISOString(),
                end: addHours(props.time, d).toISOString(),
                setFor: props.serviceId,
            })),
        },
    });
    const options = durations
        .map((d, i) => ({
            duration: d,
            price: data?.quotations?.[i]?.price || 0,
            deposit: data?.quotations?.[i]?.deposit || 0,
            remainder: data?.quotations?.[i]?.remainder || 0,
            isAvailable:
                !hasCartConflict(d) &&
                !!data?.serviceAvailabilities?.[i]?.isAvailable &&
                !!data.quotations?.[i]?.timeFullyAccounted &&
                !!data.quotations[i]?.satisfiesMinimumDuration,
        }))
        .filter((o) => o.isAvailable);
    return (
        <form
            onSubmit={handleSubmit((v) => {
                const o = options.find(
                    (o) => o.duration.toString() === v.duration,
                );
                if (!o) return;
                props.onSubmit({
                    duration: o.duration,
                    deposit: o.deposit,
                    price: o.price,
                    remainder: o.remainder,
                });
            })}
            className="flex flex-col gap-5"
        >
            <div>
                <div className="typography-overline-lg text-primary">
                    {props.resource.name}
                </div>
                <div className="typography-overline text-blue-grey-900">
                    {format(props.time, "dd MMM yyyy, iiii - h:mma")}
                </div>
            </div>
            {loading && <Spinner />}
            <Controller
                control={control}
                name="duration"
                render={({ field }) => (
                    <ToggleGroup.Root
                        type="single"
                        orientation="horizontal"
                        className="flex flex-wrap gap-4"
                        value={field.value}
                        onValueChange={field.onChange}
                    >
                        {options.map((d) => (
                            <ToggleGroup.Item
                                key={d.duration}
                                value={d.duration.toString()}
                                className="min-w-[80px] rounded-md border border-solid border-blue-grey-50 bg-white px-2 py-3 data-[state='on']:border-primary"
                            >
                                <div className="typography-main font-bold text-blue-grey-900">
                                    RM {d.price / 100}
                                </div>
                                <div className="typography-sub text-blue-grey-500">
                                    {t("hour", `${d.duration} hr(s)`, {
                                        count: d.duration,
                                    })}
                                </div>
                            </ToggleGroup.Item>
                        ))}
                    </ToggleGroup.Root>
                )}
            />
            <Button type="submit" className="w-full">
                {t("addToCart", "Add to Cart")}
            </Button>
        </form>
    );
};

const Spinner = (props: { className?: string }): JSX.Element => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        width={56}
        height={56}
        viewBox="0 0 56 56"
        className={cx("animate-spin", props.className)}
    >
        <path
            fill="#EBEEFD"
            d="M56 28a28 28 0 1 1-56 0 28 28 0 0 1 56 0ZM7 28a21 21 0 1 0 42 0 21 21 0 0 0-42 0Z"
        />
        <path
            fill="#4361EE"
            d="M12 5a28 28 0 1 1 15.5 51l.1-7A21 21 0 1 0 16 10.8l-4-5.7Z"
        />
    </svg>
);

/** isWithin checks if d is within [r.start, r.end). Needed because isWithinInterval checks [r.start, r.end]. */
const isWithin = (t: Date, r: DtRange): boolean =>
    !isEqual(t, r.endDt) &&
    isWithinInterval(t, { start: r.startDt, end: r.endDt });

// TODO: switch to schedule
const maxDurationHours = 6;

const gridH = "header";
const colT = (d: Date): string => `t${format(d, "yyyyMMddHHmm")}`;

type HasName = { name: string };
const byName = <T extends HasName>(a: T, b: T): number =>
    a.name.localeCompare(b.name, "en-MY", { numeric: true });

const isUid =
    (id: string) =>
    (e: { uid: string }): boolean =>
        e.uid === id;
const isNotArchived = (e: { archived?: string | null }): boolean => !e.archived;
const isCategory =
    (c: string) =>
    (s: { serviceCategory?: { categoryId: string } | null }): boolean =>
        s.serviceCategory?.categoryId === c;

type EntityWithName = { uid: string; name: string };
type Option = { value: string; label: string };
const toOpt = (v: EntityWithName): Option => ({ value: v.uid, label: v.name });

type DtRange = { startDt: Date; endDt: Date };
const serviceUnavailabilityDtRange = (
    service: HasMetadata | undefined,
    date: Date | undefined,
): DtRange | undefined => {
    if (!service) return undefined;
    const selectorRange = parseServiceSelectorRange(service);
    return getUnavailabilityDtRangeFromParts(date, selectorRange);
};

const getUnavailabilityDtRangeFromParts = (
    date: Date | undefined,
    range: SelectorRange | undefined,
): DtRange | undefined => {
    if (!date) return undefined;
    const startDt = addHours(date, range?.start ?? 0);
    const endDt = addDays(
        addHours(date, range?.end ?? 0),
        range && range.start >= range.end ? 1 : 0,
    );
    return getUnavailabilityDtRange(startDt, endDt);
};

// 1 hour added/subtracted for booking gap calculations
const getUnavailabilityDtRange = (s: Date, e: Date): DtRange => ({
    startDt: subHours(s, 1),
    endDt: addHours(e, maxDurationHours + 1),
});

const getScheduleDtRange = (
    service: HasMetadata | undefined,
    date: Date | undefined,
): DtRange | undefined => {
    if (!service) return undefined;
    const selectorRange = parseServiceSelectorRange(service);
    return getScheduleDtRangeFromParts(date, selectorRange);
};

const getScheduleDtRangeFromParts = (
    date: Date | undefined,
    range: SelectorRange | undefined,
): DtRange | undefined => {
    if (!date) return undefined;
    const startDt = addHours(date, range?.start ?? 0);
    const endDt = addDays(
        addHours(date, range?.end ?? 0),
        range && range.start >= range.end ? 1 : 0,
    );
    return { startDt, endDt };
};

const dtRangeISOString = (r?: DtRange): DateRangeFilter => {
    if (!r) return { startDt: "", endDt: "" };
    return {
        startDt: r.startDt.toISOString(),
        endDt: r.endDt.toISOString(),
    };
};

type ServiceMetadata = {
    bookingSelectorStart?: string;
    bookingSelectorEnd?: string;
};
type SelectorRange = { start: number; end: number };
type HasMetadata = { metadata: string };
const parseServiceSelectorRange = (
    s: HasMetadata,
): SelectorRange | undefined => {
    const { bookingSelectorStart, bookingSelectorEnd }: ServiceMetadata =
        JSON.parse(s.metadata);
    if (!bookingSelectorStart || !bookingSelectorEnd) return undefined;
    const start = parseInt(bookingSelectorStart);
    const end = parseInt(bookingSelectorEnd);
    return { start, end };
};
